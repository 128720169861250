import { IIdeonCarrierDto } from 'api/generated/models';
import ReactTable from 'components/reactTable/ReactTable';
import { SEARCH_IDEON_CARRIERS_ACTION } from 'pages/whitelistedCarrierManagement/searchIdeonCarriers';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Column, Row } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const DEFAULT_PAGE_SIZE = 50;

const IdeonCarriersTable = ({
    setSelection,
}: {
    setSelection: React.Dispatch<React.SetStateAction<Row<IIdeonCarrierDto>[]>>;
}) => {
    const { data, loading } = useSelector((state: AppStore) => ({
        data: state.ideonCarriers,
        loading: hasApiActivity(state, SEARCH_IDEON_CARRIERS_ACTION),
    }));
    const columns = useMemo<Column<IIdeonCarrierDto>[]>(
        () => [
            { accessor: (x) => x.name, Header: 'Carrier Name' },
            { accessor: (x) => x.stateAbbrev, Header: 'State' },
            { accessor: (x) => x.id, Header: 'Ideon Carrier ID' },
        ],
        []
    );

    return (
        <ReactTable
            columns={columns}
            data={data}
            isRowSelectionEnabled
            loading={loading}
            noRowsMessage="No carriers found"
            onSelectedRowsChanged={setSelection}
            pageSize={DEFAULT_PAGE_SIZE}
        />
    );
};

export default hot(module)(IdeonCarriersTable);
