import { Checkbox, Stack } from '@mui/material';
import { clearWhitelistedCarriers } from 'actions/clear';
import { getWhitelistedCarriers } from 'actions/whitelistedCarriers/getWhitelistedCarriers';
import { AddWhitelistedCarrier } from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import PageHeader from 'components/PageHeader';
import PageSectionWrapper from 'components/PageSectionWrapper';
import Select from 'components/Select';
import TableHeader from 'components/TableHeader';
import Typography from 'components/Typography';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import AddWhitelistedCarrierModal from 'pages/whitelistedCarrierManagement/AddWhitelistedCarrierModal';
import WhitelistedCarrierManagementTable from 'pages/whitelistedCarrierManagement/WhitelistedCarrierManagementTable';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';
import { arrayHasValue } from 'utilities/index';
import { years } from 'utilities/year';

const WhitelistedCarrierManagementPage = () => {
    const dispatch = useThunkDispatch();
    const [year, setYear] = useState(new Date().getFullYear());

    const { canAddWhitelistedCarriers, whitelistedIdeonCarriers } = useSelector(
        (state: AppStore) => ({
            canAddWhitelistedCarriers: hasSomePermissions(state, AddWhitelistedCarrier),
            whitelistedIdeonCarriers: state.whitelistedIdeonCarriers,
        })
    );
    const {
        closeModal: closeAddCarrierModal,
        isVisible: isAddCarrierModalVisible,
        openModal: openAddCarrierModal,
    } = useModalState();

    const [search, setSearch] = useState('');
    const [hasCompletedInitialRequest, setHasCompletedInitialRequest] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const actions: IActionButtonItems = [
        {
            isVisible: canAddWhitelistedCarriers,
            onClick: openAddCarrierModal,
            text: 'Add New Carrier',
        },
    ];
    const onYearChange = (newYear: number) => {
        setYear(newYear);
        setShowArchived(false);
        dispatch(getWhitelistedCarriers(newYear));
    };

    useEffect(() => {
        if (!arrayHasValue(whitelistedIdeonCarriers) && !hasCompletedInitialRequest) {
            dispatch(getWhitelistedCarriers(year));
            setHasCompletedInitialRequest(true);
        }
    }, [dispatch, hasCompletedInitialRequest, whitelistedIdeonCarriers, year]);

    useEffect(
        () => () => {
            dispatch(clearWhitelistedCarriers());
        },
        [dispatch]
    );

    return (
        <PageSectionWrapper>
            <TableHeader
                dropdownWhenLengthGreaterThan={0}
                items={actions}
                label={
                    <PageHeader minWidth="300px" textAlign="left" variant="h4">
                        <Typography>Whitelisted Carrier Management</Typography>
                        <Typography fontStyle="italic" fontWeight="light">
                            (Off-Exchange)
                        </Typography>
                    </PageHeader>
                }
                onChange={onChange(setSearch)}
                search={search}
            >
                <React.Fragment>
                    <Select
                        data-cy="whitelisted-carrier-year"
                        items={years}
                        name="year"
                        onChange={onChange(onYearChange)}
                        optionText="name"
                        optionValue="name"
                        sx={{ flexBasis: '30%' }}
                        value={year}
                    />
                </React.Fragment>
                <Stack direction="row" width={200}>
                    <Typography fontSize="small">
                        {`View Archived (${
                            whitelistedIdeonCarriers.filter((x) => x.isArchived).length
                        })`}
                    </Typography>
                </Stack>
                <Checkbox checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
            </TableHeader>
            <WhitelistedCarrierManagementTable globalFilter={search} showArchived={showArchived} />
            {isAddCarrierModalVisible && (
                <AddWhitelistedCarrierModal
                    initialYear={year}
                    onClose={closeAddCarrierModal}
                ></AddWhitelistedCarrierModal>
            )}
        </PageSectionWrapper>
    );
};

export default hot(module)(WhitelistedCarrierManagementPage);
