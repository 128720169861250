import {
    DELETE_WHITELISTED_CARRIER,
    deleteWhitelistedCarrier,
} from 'actions/whitelistedCarriers/deleteWhitelistedCarrier';
import {
    TOGGLE_WHITELISTED_IDEON_CARRIER_ARCHIVED_ACTION,
    toggleWhitelistedIdeonCarrierArchived,
} from 'actions/whitelistedCarriers/toggleWhitelistedIdeonCarrierArchived';
import { IWhitelistedIdeonCarrierDto } from 'api/generated/models';
import { DeleteWhitelistedCarrier, EditWhitelistedCarrier } from 'api/generated/permissions';
import ActionButtons from 'components/ActionButtons';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';

type IWhitelistedCarrierTableActionButtonsProps = { carrier: IWhitelistedIdeonCarrierDto };

export const WhitelistedCarrierTableActionButtons = ({
    carrier,
}: IWhitelistedCarrierTableActionButtonsProps) => {
    const dispatch = useThunkDispatch();
    const { canArchive, canDelete, isArchiving, isDeleting } = useSelector((state: AppStore) => ({
        canArchive: hasSomePermissions(state, EditWhitelistedCarrier),
        canDelete: hasSomePermissions(state, DeleteWhitelistedCarrier),
        isArchiving: hasApiActivity(state, TOGGLE_WHITELISTED_IDEON_CARRIER_ARCHIVED_ACTION),
        isDeleting: hasApiActivity(state, DELETE_WHITELISTED_CARRIER),
    }));

    const deleteCarrier = useCallback(async () => {
        await dispatch(deleteWhitelistedCarrier(carrier.id));
    }, [carrier.id, dispatch]);

    const archive = useCallback(async () => {
        await dispatch(toggleWhitelistedIdeonCarrierArchived(carrier.id, carrier.isArchived));
    }, [carrier.id, carrier.isArchived, dispatch]);

    const items = useMemo(
        () => [
            {
                dataCy: 'delete-whitelisted-carrier',
                isConfirm: true,
                isLoading: isDeleting,
                isVisible: canDelete,
                onClick: deleteCarrier,
                text: 'Delete',
            },
            {
                dataCy: 'toggle-whitelisted-carrier-archive',
                isConfirm: true,
                isLoading: isArchiving,
                isVisible: canArchive,
                onClick: archive,
                text: carrier.isArchived ? 'Un-archive' : 'Archive',
            },
        ],
        [archive, canArchive, canDelete, carrier.isArchived, deleteCarrier, isArchiving, isDeleting]
    );

    return (
        <ActionButtons
            ButtonProps={{ xsClassName: 'mb-2' }}
            dataCy="whitelist-table-action-buttons"
            disabled={isDeleting}
            items={items}
        />
    );
};
