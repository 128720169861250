import commonAction from 'actions/commonAction';
import IdeonCarriersApi from 'api/generated/IdeonCarriersApi';

export const SEARCH_IDEON_CARRIERS_ACTION = 'SEARCH_IDEON_CARRIERS';

export const searchIdeonCarriers = (search: string, state: string, year: number) =>
    commonAction(
        async () => new IdeonCarriersApi().searchCarriers(search, state, year),
        SEARCH_IDEON_CARRIERS_ACTION,
        {
            toastErrorMessage: 'Failed to search carriers',
        }
    );
