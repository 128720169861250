import { DELETE_WHITELISTED_CARRIER } from 'actions/whitelistedCarriers/deleteWhitelistedCarrier';
import { GET_WHITELISTED_CARRIERS_ACTION } from 'actions/whitelistedCarriers/getWhitelistedCarriers';
import { IWhitelistedIdeonCarrierDto } from 'api/generated/models';
import HeaderDropdown, {
    COMMON_HEADER_DROPDOWN_TABLE_PROPS,
} from 'components/headerDropdown/HeaderDropdown';
import ReactTable from 'components/reactTable/ReactTable';
import Skeleton from 'components/Skeleton';
import { WhitelistedCarrierTableActionButtons } from 'pages/whitelistedCarrierManagement/WhitelistedCarrierTableActionButtons';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Cell, Column, Filters, FilterTypes, HeaderProps } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { DEFAULT_ACTIONS_COLUMN_PROPS } from 'utilities/reactTable';

const DEFAULT_PAGE_SIZE = 50;

const ActionsCell = ({ row }: Cell<IWhitelistedIdeonCarrierDto>) => (
    <WhitelistedCarrierTableActionButtons carrier={row.original} />
);

const STATE_FILTER_ID = 'stateFilter';
const STATE_COLUMN_ID = 'state';
const filterTypes: FilterTypes<IWhitelistedIdeonCarrierDto> = {
    [STATE_FILTER_ID]: (rows, _, filterValue: string[]) =>
        rows.filter(({ original: { stateAbbrev } }) => filterValue.contains(stateAbbrev)),
};

const WhitelistedCarrierManagementTable = ({
    globalFilter,
    showArchived,
}: {
    globalFilter: string | undefined;
    showArchived: boolean;
}) => {
    const { data, isLoading } = useSelector((state: AppStore) => ({
        data: state.whitelistedIdeonCarriers.filter((x) =>
            showArchived ? x.isArchived : !x.isArchived
        ),
        isLoading: hasApiActivity(
            state,
            GET_WHITELISTED_CARRIERS_ACTION,
            DELETE_WHITELISTED_CARRIER
        ),
    }));

    const uniqueStateAbbrevs = Array.from(
        new Set(data.map((x) => x.stateAbbrev ?? ''))
    ).sort((a, b) => a.localeCompare(b));

    const StateHeader = React.useCallback(
        ({ column: { id, filterValue, setFilter } }: HeaderProps<IWhitelistedIdeonCarrierDto>) => (
            <HeaderDropdown
                columnId={id}
                id="state-filter-dropdown"
                options={uniqueStateAbbrevs}
                selected={filterValue}
                setSelected={setFilter}
                title="State"
            />
        ),
        [uniqueStateAbbrevs]
    );
    const columns = useMemo<Column<IWhitelistedIdeonCarrierDto>[]>(
        () => [
            { accessor: (x) => x.carrierName, Header: 'Carrier Name' },
            {
                ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
                accessor: (x) => x.stateAbbrev,
                disableGlobalFilter: true,
                filter: STATE_FILTER_ID,
                Header: StateHeader,
                id: STATE_COLUMN_ID,
            },
            { accessor: (x) => x.carrierId, Header: 'Ideon Carrier ID' },
            { ...DEFAULT_ACTIONS_COLUMN_PROPS, Cell: ActionsCell, width: 220 },
        ],
        [StateHeader]
    );

    const initialFilters: Filters<IWhitelistedIdeonCarrierDto> = [
        { id: STATE_COLUMN_ID, value: uniqueStateAbbrevs },
    ];

    return (
        <Skeleton isEnabled={isLoading}>
            <ReactTable
                columns={columns}
                data={data}
                filterTypes={filterTypes}
                globalFilter={globalFilter}
                initialFilters={initialFilters}
                loading={isLoading}
                pageSize={DEFAULT_PAGE_SIZE}
            />
        </Skeleton>
    );
};

export default hot(module)(WhitelistedCarrierManagementTable);
