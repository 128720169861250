import {
    DELETE_HOUSEHOLD_MEMBER_YEAR_DATA_ACTION,
    deleteHouseholdMemberYearData,
} from 'actions/householdMember/deleteHouseholdMemberYearData';
import { getPathwayUserProfileInfo } from 'actions/pathwayBlueprint/getPathwayUserProfileInfo';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { getYearlyUserInfo } from 'actions/user/getYearlyUserInfo';
import { HouseholdMemberTypes } from 'api/generated/enums';
import { ViewPathwayBlueprints } from 'api/generated/permissions';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import PageHeader from 'components/PageHeader';
import useContributionProps from 'hooks/useContributionProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import sortBy from 'lodash/sortBy';
import HouseholdMemberProfileCard from 'pages/profile/householdMemberInfo/HouseholdMemberProfileCard';
import React, { ReactNode, useCallback, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IHouseholdMemberWithAge } from 'reducers/householdMembers';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';

type IHouseholdMemberInfoSectionProps = {
    buttonLabel?: string;
    householdMemberTypeId: HouseholdMemberTypes;
    label: ReactNode;
    onAddClick: (householdMemberTypeId: HouseholdMemberTypes) => () => void;
    showAddButton: boolean;
};
const HouseholdMemberInfoSection = ({
    buttonLabel,
    householdMemberTypeId,
    label,
    onAddClick,
    showAddButton,
}: IHouseholdMemberInfoSectionProps) => {
    const dispatch = useThunkDispatch();
    const { userId, currentUserId } = useUserProps();
    const isCurrentlyLoggedInUser = userId === currentUserId;
    const { hasAnyContributionInfo } = useContributionProps();
    const [confirmationModalParam, setConfirmationModalParam] = useState<IHouseholdMemberWithAge>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const {
        canViewPathwayBlueprints,
        householdMembers,
        isDeletingHouseholdMember,
        showActivity,
    } = useSelector((state: AppStore) => ({
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
        householdMembers: state.householdMembers,
        isDeletingHouseholdMember: hasApiActivity(state, DELETE_HOUSEHOLD_MEMBER_YEAR_DATA_ACTION),
        showActivity: state.apiActivity[GET_HOUSEHOLD_ACTION],
    }));
    const handleOpenConfirmationModal = useCallback(
        (householdMember: IHouseholdMemberWithAge) => () => {
            setShowConfirmationModal(true);
            setConfirmationModalParam(householdMember);
        },
        []
    );
    const closeConfirmationModal = useCallback(() => {
        setShowConfirmationModal(false);
        setConfirmationModalParam(undefined);
    }, []);
    const removeMember = useCallback(async () => {
        await dispatch(
            deleteHouseholdMemberYearData(
                confirmationModalParam?.householdMemberId,
                confirmationModalParam?.year
            )
        );
        await dispatch(getYearlyUserInfo(userId, confirmationModalParam?.year));
        if (hasAnyContributionInfo && canViewPathwayBlueprints) {
            await dispatch(getPathwayUserProfileInfo(userId, confirmationModalParam?.year));
        }
        closeConfirmationModal();
    }, [
        canViewPathwayBlueprints,
        closeConfirmationModal,
        confirmationModalParam,
        dispatch,
        hasAnyContributionInfo,
        userId,
    ]);
    return (
        <React.Fragment>
            {showConfirmationModal && (
                <ConfirmationModal
                    body={`Are you sure you want to remove ${confirmationModalParam?.firstName}?`}
                    onNoClick={closeConfirmationModal}
                    onYesClick={removeMember}
                    showActivity={isDeletingHouseholdMember}
                    title="Remove Household Member"
                />
            )}
            <PageHeader variant="h4">{label}</PageHeader>
            <hr />
            {showAddButton && (
                <Row className="justify-content-center my-1">
                    <Button
                        onClick={onAddClick(householdMemberTypeId)}
                        size="small"
                        variant="outlined"
                    >
                        Add {buttonLabel ?? label}
                    </Button>
                </Row>
            )}
            {showActivity ? (
                <Skeleton count={3} height="744px" />
            ) : (
                sortBy(
                    householdMembers.filter(
                        (x) =>
                            x.householdMemberTypeId === householdMemberTypeId &&
                            (!isCurrentlyLoggedInUser || (isCurrentlyLoggedInUser && !x.isDeceased))
                    ),
                    'dateOfBirth'
                ).map((householdMember) => (
                    <HouseholdMemberProfileCard
                        handleRemove={handleOpenConfirmationModal(householdMember)}
                        householdMember={householdMember}
                        key={householdMember.householdMemberId}
                    />
                ))
            )}
        </React.Fragment>
    );
};

export default hot(module)(HouseholdMemberInfoSection);
