import { Card, CardContent, CardHeader, Chip } from '@mui/material';
import { setDrugsForHouseholdMember } from 'actions/drug/setDrugsForHouseholdMember';
import { deleteHouseholdMemberAddress } from 'actions/householdMember/deleteHouseholdMemberAddress';
import { editHouseholdMemberAddress } from 'actions/householdMember/editHouseholdMemberAddress';
import {
    PATCH_HOUSEHOLD_MEMBER_ACTION,
    patchHouseholdMember,
} from 'actions/householdMember/patchHouseholdMember';
import { getPathwayUserProfileInfo } from 'actions/pathwayBlueprint/getPathwayUserProfileInfo';
import { setProvidersForHouseholdMember } from 'actions/provider/setProvidersForHouseholdMember';
import { getTaxData } from 'actions/taxData/getTaxData';
import { editSocialSecurityNumbers } from 'actions/user/editSocialSecurityNumbers';
import { getUserBudget } from 'actions/user/getUserBudget';
import { getYearlyUserInfo } from 'actions/user/getYearlyUserInfo';
import { AddressTypes, EntityType, HouseholdMemberTypes, SurveyTypes } from 'api/generated/enums';
import { IAddress, IDrug, IHouseholdMemberDto, IProvider } from 'api/generated/models';
import { ViewPathwayBlueprints, ViewTaxData } from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import Button from 'components/Button';
import EditableCurrencyAttribute from 'components/EditableCurrencyAttribute';
import EditableDateAttribute from 'components/EditableDateAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableSocialSecurityAttribute from 'components/EditableSocialSecurityAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import HeightProfileAttribute from 'components/HeightProfileAttribute';
import PageHeader from 'components/PageHeader';
import WeightProfileAttribute from 'components/WeightProfileAttribute';
import GENDERS from 'constants/genders';
import {
    getOtherCoverageData,
    otherCoverageEligibilities,
} from 'constants/otherCoverageEligibilities';
import useContributionProps from 'hooks/useContributionProps';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import CommonNotesInfo from 'pages/profile/CommonNotesInfo';
import EditDrugsModal from 'pages/profile/EditDrugsModal';
import EditProvidersModal from 'pages/profile/EditProvidersModal';
import EditableAddressAttribute from 'pages/profile/EditableAddressAttribute';
import MayIncludeAdditionalIncomeMessage from 'pages/profile/MayIncludeAdditionalIncomeMessage';
import OtherCoverageSourceInfo from 'pages/profile/householdMemberInfo/OtherCoverageSourceInfo';
import { getCanEditVerifiedInfo } from 'pages/profile/profileSelectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { genderList } from 'reducers/options';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { isFalse, isTrue, nameof, updateFlag } from 'utilities';
import { formatDateForDisplay, formatGender } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

const needsCoverageName = nameof<IHouseholdMemberDto>('needsCoverage');

type IHouseholdMemberProfileCardProps = {
    handleRemove: () => void;
    householdMember: IHouseholdMemberDto;
};

const MAX_SUFFIX_LENGTH = 10;
const MAX_EMPLOYER_NAME_LENGTH = 500;
const HouseholdMemberProfileCard = ({
    handleRemove,
    householdMember,
}: IHouseholdMemberProfileCardProps) => {
    const {
        address: homeAddress,
        alaskanNative: initialAlaskanNative,
        americanIndian: initialAmericanIndian,
        dateOfBirth: initialDateOfBirth,
        employerName: initialEmployerName,
        firstName: initialFirstName,
        fosterCareAt18OrOlder: initialFosterCareAt18OrOlder,
        fullTimeStudent: initialFullTimeStudent,
        gender: initialGender,
        householdMemberId,
        householdMemberTypeId,
        income: initialIncome,
        isEmployed: initialIsEmployed,
        hasSameEmployer: initialHasSameEmployer,
        isPregnant: initialIsPregnant,
        lastName: initialLastName,
        mailingAddress,
        middleName: initialMiddleName,
        naturalizedCitizen: initialNaturalizedCitizen,
        needsCoverage: initialNeedsCoverage,
        otherCoverageEligibilities: initialOtherCoverageEligibilities,
        preferredName: initialPreferredName,
        socialSecurityNumber: initialSocialSecurityNumber,
        specialNeeds: initialSpecialNeeds,
        suffix: initialSuffix,
        usCitizen: initialUsCitizen,
        userId,
        usesTobacco: initialUsesTobacco,
        isDeceased: initialIsDeceased,
    } = householdMember;
    const dispatch = useThunkDispatch();
    const teamProps = useTeamProps();
    const userProps = useUserProps();
    const { hasAnyContributionInfo } = useContributionProps();
    const {
        canEditVerifiedInfo,
        canViewPathwayBlueprints,
        canViewTaxData,
        householdMemberDrugs,
        householdMemberProviders,
        isPatchHouseholdMemberLoading,
        isRh,
        selectedYear,
    } = useSelector((state: AppStore) => ({
        canEditVerifiedInfo: getCanEditVerifiedInfo(state, teamProps, userProps),
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
        canViewTaxData: hasSomePermissions(state, ViewTaxData),
        householdMemberDrugs: state.householdDrugs.filter((p) => p.entityId === householdMemberId),
        householdMemberProviders: state.householdProviders.filter(
            (p) => p.entityId === householdMemberId
        ),
        isPatchHouseholdMemberLoading: hasApiActivity(state, PATCH_HOUSEHOLD_MEMBER_ACTION),
        isRh: isRhSelector(state),
        selectedYear: +state.profileState.selectedYear,
    }));
    const otherCoverage = useMemo(() => getOtherCoverageData(initialOtherCoverageEligibilities), [
        initialOtherCoverageEligibilities,
    ]);
    const getOtherCoverageBoolValue = useCallback(
        (otherCoverageEligibility) =>
            !!otherCoverage.find((x) => x['status'] === otherCoverageEligibility)?.['status'],
        [otherCoverage]
    );
    const [alaskanNative, setAlaskanNative] = useState(initialAlaskanNative?.toString() || '');
    const [americanIndian, setAmericanIndian] = useState(initialAmericanIndian?.toString() || '');
    const [dateOfBirth, setDateOfBirth] = useState(formatDateForDisplay(initialDateOfBirth));
    const [employerName, setEmployerName] = useState(initialEmployerName ?? '');
    const [socialSecurityNumber, setSocialSecurityNumber] = useState(initialSocialSecurityNumber);
    const [firstName, setFirstName] = useState(initialFirstName);
    const [fosterCareAt18OrOlder, setFosterCareAt18OrOlder] = useState(
        initialFosterCareAt18OrOlder?.toString() || ''
    );

    const [isDeceased, setIsDeceased] = useState(initialIsDeceased?.toString() || '');
    const [fullTimeStudent, setFullTimeStudent] = useState(
        initialFullTimeStudent?.toString() || ''
    );
    const [gender, setGender] = useState(initialGender);
    const [hasDependentEmployerOther, setHasDependentEmployerOther] = useState(
        getOtherCoverageBoolValue(otherCoverageEligibilities.DependentEmployer)?.toString() ||
            'false'
    );
    const [hasParentOtherCoverage, setHasParentOtherCoverage] = useState(
        getOtherCoverageBoolValue(otherCoverageEligibilities.Parent)?.toString() || 'false'
    );
    const [isPregnant, setIsPregnant] = useState(initialIsPregnant?.toString());
    const [lastName, setLastName] = useState(initialLastName);
    const [middleName, setMiddleName] = useState(initialMiddleName || '');
    const [naturalizedCitizen, setNaturalizedCitizen] = useState(
        initialNaturalizedCitizen?.toString() || ''
    );
    const [isEmployed, setIsEmployed] = useState(initialIsEmployed?.toString() || '');
    const [hasSameEmployer, setHasSameEmployer] = useState(
        initialHasSameEmployer?.toString() || ''
    );
    const [needsCoverage, setNeedsCoverage] = useState(initialNeedsCoverage?.toString() || '');
    const [preferredName, setPreferredName] = useState(initialPreferredName || '');
    const [specialNeeds, setSpecialNeeds] = useState(initialSpecialNeeds?.toString() || '');
    const [suffix, setSuffix] = useState(initialSuffix || '');
    const [usCitizen, setUsCitizen] = useState(initialUsCitizen?.toString() || '');
    const [income, setIncome] = useState(initialIncome?.toString() || '');
    const [usesTobacco, setUsesTobacco] = useState(initialUsesTobacco?.toString() || '');
    useEffect(() => {
        setIncome(initialIncome?.toString() ?? '');
    }, [initialIncome]);
    const getNewOtherCoverageValue = useCallback(
        (value, eligibility) =>
            updateFlag(isTrue(value), initialOtherCoverageEligibilities, eligibility),
        [initialOtherCoverageEligibilities]
    );
    const saveHouseholdMember = useCallback(
        async (name, value) => {
            if (name === 'hasDependentEmployerOther') {
                name = 'otherCoverageEligibilities';
                value = getNewOtherCoverageValue(
                    value,
                    otherCoverageEligibilities.DependentEmployer
                );
            } else if (name === 'hasParentOtherCoverage') {
                name = 'otherCoverageEligibilities';
                value = getNewOtherCoverageValue(value, otherCoverageEligibilities.Parent);
            }
            await dispatch(
                patchHouseholdMember(householdMemberId, selectedYear, { [name]: value })
            );
            if (hasAnyContributionInfo && canViewPathwayBlueprints) {
                await dispatch(getPathwayUserProfileInfo(userId, selectedYear));
            }
            if (name === needsCoverageName) {
                await dispatch(getYearlyUserInfo(userId, selectedYear));
            }
        },
        [
            canViewPathwayBlueprints,
            dispatch,
            getNewOtherCoverageValue,
            hasAnyContributionInfo,
            householdMemberId,
            selectedYear,
            userId,
        ]
    );
    const saveSocialSecurityNumber = useCallback(
        async (_name, newSocialSecurityNumber) => {
            await dispatch(
                editSocialSecurityNumbers(
                    userId,
                    selectedYear,
                    {
                        [householdMemberId]: {
                            entityId: householdMemberId,
                            entityType: EntityType.HouseholdMember,
                            socialSecurityNumber: newSocialSecurityNumber,
                        },
                    },
                    true
                )
            );
        },
        [dispatch, householdMemberId, selectedYear, userId]
    );
    const saveAddress = useCallback(
        async (address: IAddress, type: AddressTypes) => {
            await dispatch(
                editHouseholdMemberAddress(householdMemberId, selectedYear, address, type)
            );
        },
        [dispatch, householdMemberId, selectedYear]
    );

    const deleteAddress = useCallback(
        async (addressId: string, typeId: AddressTypes) => {
            await dispatch(deleteHouseholdMemberAddress(addressId, householdMemberId, typeId));
        },
        [dispatch, householdMemberId]
    );
    const saveIncome: ISaveEditableTextField<IHouseholdMemberDto> = async (name, value) => {
        await saveHouseholdMember(name, value);
        await dispatch(getUserBudget(userId, +selectedYear));
        if (canViewTaxData) {
            dispatch(getTaxData(userId, selectedYear));
        }
    };

    const commonProps = useCallback(
        <T,>(setValue: React.Dispatch<React.SetStateAction<T>>) => ({
            onChange: onChange(setValue),
            readonly: !canEditVerifiedInfo,
            save: saveHouseholdMember,
        }),
        [canEditVerifiedInfo, saveHouseholdMember]
    );
    const setDrugs = useCallback(
        (options: IDrug[]) => setDrugsForHouseholdMember(householdMemberId, selectedYear, options),
        [householdMemberId, selectedYear]
    );
    const setProviders = useCallback(
        (options: IProvider[]) =>
            setProvidersForHouseholdMember(householdMemberId, selectedYear, options),
        [householdMemberId, selectedYear]
    );
    const isSpouse = householdMemberTypeId === HouseholdMemberTypes.Spouse;
    const isDependent = householdMemberTypeId === HouseholdMemberTypes.Dependent;
    const isUnclaimed = householdMemberTypeId === HouseholdMemberTypes.Unclaimed;
    const hasCompletedStandardSurvey =
        userProps.memberVerifiedInfo?.surveyTypeCompleted === SurveyTypes.Standard;
    const items: IActionButtonItems = useMemo(
        () => [
            {
                isConfirm: true,
                isLoading: isPatchHouseholdMemberLoading,
                isVisible: isDependent,
                onClick: async () =>
                    saveHouseholdMember('householdMemberTypeId', HouseholdMemberTypes.Unclaimed),
                text: 'Change to Unclaimed Household Member',
            },
            {
                isConfirm: true,
                isLoading: isPatchHouseholdMemberLoading,
                isVisible: isUnclaimed,
                onClick: async () =>
                    saveHouseholdMember('householdMemberTypeId', HouseholdMemberTypes.Dependent),
                text: 'Change to Dependent',
            },
            {
                isVisible: true,
                onClick: handleRemove,
                text: 'Remove',
            },
        ],
        [handleRemove, isPatchHouseholdMemberLoading, isDependent, isUnclaimed, saveHouseholdMember]
    );
    return (
        <Card sx={{ marginTop: 2, padding: 0 }}>
            <CardHeader
                action={
                    <React.Fragment>
                        {canEditVerifiedInfo &&
                            (isSpouse ? (
                                <Button
                                    ButtonClassName="p-0 font-13"
                                    color="error"
                                    onClick={handleRemove}
                                    variant="text"
                                >
                                    Remove
                                </Button>
                            ) : (
                                <ActionButtons
                                    dropdownWhenLengthGreaterThan={0}
                                    isThreeDotButton
                                    items={items}
                                />
                            ))}
                    </React.Fragment>
                }
                sx={{ paddingBottom: 0, paddingLeft: 3 }}
                title={
                    <PageHeader variant="h4">
                        {firstName}
                        {initialIsDeceased && (
                            <Chip
                                color="error"
                                label="Deceased"
                                size="small"
                                sx={{ ml: 1 }}
                                variant="outlined"
                            />
                        )}
                    </PageHeader>
                }
                titleTypographyProps={{ variant: 'h4' }}
            />
            <CardContent sx={{ paddingTop: 0 }}>
                <hr />

                <Row>
                    <Col md="6" sm="12">
                        <EditableTextAttribute
                            {...commonProps(setFirstName)}
                            label="Legal First Name"
                            name="firstName"
                            placeholder="Enter a first name"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Legal First Name')}
                            value={firstName}
                        />

                        <EditableTextAttribute
                            {...commonProps(setLastName)}
                            defaultValue="Not added"
                            label="Last Name"
                            name="lastName"
                            placeholder="Enter a last name"
                            validationSchema={string()
                                .trim()
                                .required()
                                .label('Last Name')}
                            value={lastName}
                        />

                        <EditableTextAttribute
                            {...commonProps(setPreferredName)}
                            isOptional
                            label="Preferred Name"
                            name="preferredName"
                            placeholder="Enter a preferred name"
                            validationSchema={string()
                                .trim()
                                .label('Preferred Name')}
                            value={preferredName}
                        />

                        <EditableDateAttribute
                            {...commonProps(setDateOfBirth)}
                            label="Date of Birth"
                            name="dateOfBirth"
                            validationSchema={string()
                                .required()
                                .isValidDate(false)
                                .label('Date of Birth')}
                            value={dateOfBirth}
                        />

                        <EditableSocialSecurityAttribute
                            {...commonProps(setSocialSecurityNumber)}
                            isOptional
                            label="Social Security Number"
                            name="socialSecurityNumber"
                            save={saveSocialSecurityNumber}
                            userId={userId}
                            value={socialSecurityNumber}
                        />

                        <EditableSelectAttribute
                            {...commonProps(setGender)}
                            defaultText="Select a gender"
                            formatter={(x) => formatGender(x as GENDERS)}
                            items={genderList}
                            label="Gender"
                            name="gender"
                            optionText="value"
                            optionValue="id"
                            validationSchema={string()
                                .required()
                                .label('Gender')}
                            value={gender}
                        />

                        <EditableAddressAttribute
                            address={homeAddress}
                            deleteAddress={deleteAddress}
                            isHouseholdMember
                            readonly={!canEditVerifiedInfo}
                            saveAddress={saveAddress}
                            type={AddressTypes.Home}
                            year={selectedYear}
                        />

                        <EditableAddressAttribute
                            address={mailingAddress}
                            deleteAddress={deleteAddress}
                            isHouseholdMember
                            readonly={!canEditVerifiedInfo}
                            saveAddress={saveAddress}
                            type={AddressTypes.Mailing}
                            year={selectedYear}
                        />

                        {isRh && (
                            <EditableYesNoSelectAttribute
                                {...commonProps(setIsDeceased)}
                                isOptional
                                label="Is Deceased"
                                name="isDeceased"
                                value={isDeceased}
                            />
                        )}

                        <HeightProfileAttribute
                            heightInInches={householdMember.heightInInches}
                            readonly={!canEditVerifiedInfo}
                            save={saveHouseholdMember}
                        />

                        <WeightProfileAttribute
                            readonly={!canEditVerifiedInfo}
                            save={saveHouseholdMember}
                            weightInPounds={householdMember.weightInPounds}
                        />

                        {gender === GENDERS.FEMALE && hasCompletedStandardSurvey && (
                            <EditableYesNoSelectAttribute
                                {...commonProps(setIsPregnant)}
                                label="Pregnant"
                                name="isPregnant"
                                value={isPregnant}
                            />
                        )}

                        <EditableYesNoSelectAttribute
                            {...commonProps(setFullTimeStudent)}
                            isOptional
                            label="Full-Time Student"
                            name="fullTimeStudent"
                            value={fullTimeStudent}
                        />

                        <EditableYesNoSelectAttribute
                            {...commonProps(setFosterCareAt18OrOlder)}
                            isOptional
                            label="Foster Care at 18 or Older"
                            name="fosterCareAt18OrOlder"
                            value={fosterCareAt18OrOlder}
                        />

                        <CommonNotesInfo
                            data={householdMemberProviders}
                            EditNotesModal={(close) => (
                                <EditProvidersModal
                                    close={close}
                                    providers={householdMemberProviders}
                                    setProviders={setProviders}
                                />
                            )}
                            label="Preferred Providers"
                            readonly={!canEditVerifiedInfo}
                        />

                        <CommonNotesInfo
                            data={householdMemberDrugs}
                            EditNotesModal={(close) => (
                                <EditDrugsModal
                                    close={close}
                                    drugs={householdMemberDrugs}
                                    setDrugs={setDrugs}
                                />
                            )}
                            label="Prescriptions"
                            readonly={!canEditVerifiedInfo}
                        />
                    </Col>
                    <Col md="6" sm="12">
                        <EditableTextAttribute
                            label="Middle Name"
                            {...commonProps(setMiddleName)}
                            isOptional
                            name="middleName"
                            placeholder="Enter a middle name"
                            validationSchema={string()
                                .trim()
                                .label('Middle Name')}
                            value={middleName}
                        />

                        <EditableTextAttribute
                            {...commonProps(setSuffix)}
                            isOptional
                            label="Suffix"
                            name="suffix"
                            placeholder="Enter a suffix"
                            validationSchema={string()
                                .trim()
                                .max(MAX_SUFFIX_LENGTH)
                                .label('Suffix')}
                            value={suffix}
                        />

                        {hasCompletedStandardSurvey && (
                            <EditableCurrencyAttribute
                                {...commonProps(setIncome)}
                                footer={<MayIncludeAdditionalIncomeMessage />}
                                isOptional
                                label="Income"
                                name="income"
                                save={saveIncome}
                                validationSchema={string()
                                    .trim()
                                    .label('Income')}
                                value={income}
                            />
                        )}

                        <EditableYesNoSelectAttribute
                            {...commonProps(setUsesTobacco)}
                            label="Tobacco User"
                            name="usesTobacco"
                            value={usesTobacco}
                        />

                        {hasCompletedStandardSurvey &&
                            (isSpouse ? (
                                <EditableYesNoSelectAttribute
                                    {...commonProps(setHasParentOtherCoverage)}
                                    label="Parent Other Coverage"
                                    name="hasParentOtherCoverage"
                                    value={hasParentOtherCoverage}
                                />
                            ) : (
                                <EditableYesNoSelectAttribute
                                    {...commonProps(setHasDependentEmployerOther)}
                                    label="Employer Other Coverage"
                                    name="hasDependentEmployerOther"
                                    value={hasDependentEmployerOther}
                                />
                            ))}

                        {hasCompletedStandardSurvey && (
                            <EditableYesNoSelectAttribute
                                {...commonProps(setIsEmployed)}
                                label="Employed"
                                name="isEmployed"
                                value={isEmployed}
                            />
                        )}

                        {isTrue(isEmployed) &&
                            !isTrue(hasSameEmployer) &&
                            hasCompletedStandardSurvey && (
                                <EditableTextAttribute
                                    {...commonProps(setEmployerName)}
                                    isOptional
                                    label="Employer Name"
                                    name="employerName"
                                    placeholder="Enter a employerName"
                                    validationSchema={string()
                                        .trim()
                                        .max(MAX_EMPLOYER_NAME_LENGTH)
                                        .label('Employer Name')}
                                    value={employerName}
                                />
                            )}

                        {isSpouse && (
                            <EditableYesNoSelectAttribute
                                {...commonProps(setHasSameEmployer)}
                                isOptional
                                label="Same Employer as Primary"
                                name="hasSameEmployer"
                                value={hasSameEmployer}
                            />
                        )}

                        <EditableYesNoSelectAttribute
                            {...commonProps(setNeedsCoverage)}
                            infoTooltip="Whether this member of the household needs major medical benefits managed via Remodel Health."
                            label="Needs Major Medical Coverage"
                            name={needsCoverageName}
                            value={needsCoverage}
                        />
                        {isFalse(needsCoverage) && (
                            <OtherCoverageSourceInfo
                                householdMember={householdMember}
                                readonly={!canEditVerifiedInfo}
                            />
                        )}

                        <EditableYesNoSelectAttribute
                            {...commonProps(setUsCitizen)}
                            isOptional
                            label="U.S. Citizen"
                            name="usCitizen"
                            value={usCitizen}
                        />

                        <EditableYesNoSelectAttribute
                            {...commonProps(setNaturalizedCitizen)}
                            isOptional
                            label="Naturalized Citizen"
                            name="naturalizedCitizen"
                            value={naturalizedCitizen}
                        />

                        <EditableYesNoSelectAttribute
                            {...commonProps(setAmericanIndian)}
                            isOptional
                            label="American Indian"
                            name="americanIndian"
                            value={americanIndian}
                        />

                        <EditableYesNoSelectAttribute
                            {...commonProps(setAlaskanNative)}
                            isOptional
                            label="Alaskan Native"
                            name="alaskanNative"
                            value={alaskanNative}
                        />

                        <EditableYesNoSelectAttribute
                            {...commonProps(setSpecialNeeds)}
                            isOptional
                            label="Special Needs"
                            name="specialNeeds"
                            value={specialNeeds}
                        />
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};

export default hot(module)(HouseholdMemberProfileCard);
