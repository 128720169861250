import Grid from '@mui/material/Grid';
import {
    patchSelectedPlans,
    PATCH_SELECTED_PLAN_ACTION,
} from 'actions/selectedPlan/patchSelectedPlans';
import { PlanStateIds, PlanTypeIds } from 'api/generated/enums';
import { ISelectedPlan, IUser } from 'api/generated/models';
import {
    CreateSelectedPlans,
    DeleteSelectedPlans,
    EditSelectedPlans,
} from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import BenefitCardContent from 'components/BenefitCardContent';
import ConfirmationModal from 'components/ConfirmationModal';
import Icon from 'components/Icon';
import SingleButtonModal from 'components/SingleButtonModal';
import { MEDISHARE_GUIDELINES_URL } from 'constants/miscUrls';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { showPlanModal, showRemovePlanModal } from 'pages/benefits/benefitsActions';
import React, { useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { deleteSelectedPlan } from 'actions/selectedPlan/deleteSelectedPlan';

type IBenefitCardProps = {
    benefit: ISelectedPlan;
    user: IUser | undefined;
};

const BenefitCard = ({ benefit, user }: IBenefitCardProps) => {
    const dispatch = useThunkDispatch();
    const [showDuplicateConfirmationModal, setShowDuplicateConfirmationModal] = useState(false);
    const [showFuturePlanDeleteModal, setShowFuturePlanDeleteModal] = useState(false);
    const [showUnableToTerminateModal, setShowUnableToTerminateModal] = useState(false);
    const {
        canDeletePlans,
        canCreatePlans,
        canEditPlans,
        canViewApplicationLink,
        householdMembers,
        isSettingTerminated,
    } = useSelector((state: AppStore) => ({
        canCreatePlans: state.login.up.includes(CreateSelectedPlans),
        canDeletePlans: state.login.up.includes(DeleteSelectedPlans),
        canEditPlans: state.login.up.includes(EditSelectedPlans),
        canViewApplicationLink: isRhSelector(state) || isAdvisorSelector(state),
        householdMembers: state.householdMembers,
        isSettingTerminated: hasApiActivity(state, PATCH_SELECTED_PLAN_ACTION),
    }));
    const { applicationLink, benefitsLink, planTypeId } = benefit;
    const isMediShare = useMemo(() => planTypeId === PlanTypeIds.MediShare, [planTypeId]);
    const items = useMemo(
        () =>
            [
                {
                    dataCy: 'view-documents',
                    href: isMediShare ? MEDISHARE_GUIDELINES_URL : benefitsLink,
                    isVisible: isMediShare || benefitsLink,
                    rel: 'noopener noreferrer',
                    target: '_blank',
                    text: (
                        <span>
                            View Documents <Icon className="pl-1">exit</Icon>
                        </span>
                    ),
                },
                {
                    dataCy: 'application-link',
                    href: applicationLink,
                    isVisible: hasValue(applicationLink) && canViewApplicationLink,
                    rel: 'noopener noreferrer',
                    target: '_blank',
                    text: (
                        <span>
                            View Application <Icon className="pl-1">exit</Icon>
                        </span>
                    ),
                },
                {
                    dataCy: 'edit-plan',
                    isVisible: canEditPlans,
                    onClick: () => dispatch(showPlanModal(benefit)),
                    text: 'Edit',
                },
                {
                    dataCy: 'duplicate-plan',
                    isVisible: canCreatePlans,
                    onClick: () => {
                        if (benefit.planStateId === PlanStateIds.Terminated) {
                            dispatch(
                                showPlanModal(({
                                    ...benefit,
                                    selectedPlanId: undefined,
                                } as unknown) as ISelectedPlan)
                            );
                        } else if(benefit.year <= Number(user?.activeDate?.getYear())){

                            setShowDuplicateConfirmationModal(true);

                        } else{
                            setShowFuturePlanDeleteModal(true);
                        }

                    },
                    text: 'Duplicate',
                },
                {
                    className: 'text-danger',
                    isVisible: canDeletePlans,
                    onClick: () => dispatch(showRemovePlanModal(benefit)),
                    text: 'Delete',
                },
            ] as IActionButtonItems,
        [isMediShare, benefitsLink, applicationLink, canViewApplicationLink, canEditPlans, canCreatePlans, canDeletePlans, dispatch, benefit, user?.activeDate]
    );
    const onNoClick = () => {
        setShowDuplicateConfirmationModal(false);
        dispatch(
            showPlanModal(({ ...benefit, selectedPlanId: undefined } as unknown) as ISelectedPlan)
        );
    };
    const onYesClick = async () => {
       if (!hasValue(benefit.coverageStartDate)) {
            setShowDuplicateConfirmationModal(false);
            setShowUnableToTerminateModal(true);
        } else {
            await dispatch(
                patchSelectedPlans(benefit.selectedPlanId, {
                    coverageEndDate: new Date().toUTCString(),
                    planStateId: PlanStateIds.Terminated,
                })
            );
            onNoClick();
        }
    };

    const onYesOption = async () => {
        const selectedPlan = benefit.selectedPlanId;
        await dispatch(deleteSelectedPlan(selectedPlan));
        setShowFuturePlanDeleteModal(false);
        dispatch(
            showPlanModal(({ ...benefit, selectedPlanId: undefined } as unknown) as ISelectedPlan)
        );
    };

    const onNoOption = async () => {
        setShowFuturePlanDeleteModal(false);
    };
    const coverageStartDateText =
        benefit.planTypeId === PlanTypeIds.MediShare ? 'a Member Since' : 'an Effective';

    const duplicateConfirmationModalBody = `The original plan's state cannot be set to "Terminated" until the plan has ${coverageStartDateText} Date.
    You will have to manually set the ${coverageStartDateText} date as well as the status to "Terminated" for this plan through the edit action.`;

    return (
        <Grid item sm={6} xs={12}>
            {showDuplicateConfirmationModal && (
                <ConfirmationModal
                    body={`Would you like to set the original plan (${benefit.year} - ${benefit.planName}) to a "Terminated" state?`}
                    onNoClick={onNoClick}
                    onYesClick={onYesClick}
                    showActivity={isSettingTerminated}
                    title="Confirm"
                />
            )}
            {showUnableToTerminateModal && (
                <SingleButtonModal
                    body={duplicateConfirmationModalBody}
                    buttonClickHandler={onNoClick}
                    buttonText="Continue"
                    title="Information"
                />
            )}

            {showFuturePlanDeleteModal && (
                <ConfirmationModal
                    body={'This plan\'s year is in the future, do you want to delete this plan?'}
                    onNoClick={onNoOption}
                    onYesClick={onYesOption}
                    showActivity={isSettingTerminated}
                    title="Confirm"
                />
            )}
            <BenefitCardContent
                actionItems={items}
                benefit={benefit}
                householdMembers={householdMembers}
                showCreatedDate
                user={user}
            />
        </Grid>
    );
};

export default hot(module)(BenefitCard);
