import { clearPagedUsers, clearUserProfilesMetadata } from 'actions/clear';
import { GET_CURRENT_USER_BUDGETS_FOR_TEAM_ACTION } from 'actions/team/getUserBudgetsForTeam';
import {
    GET_USER_PROFILES_METADATA_ACTION,
    getUserProfilesMetadata,
} from 'actions/user/getUserProfilesMetadata';
import {
    LIST_PAGED_USER_PROFILES_ACTION,
    listPagedUserProfiles,
} from 'actions/user/listPagedUserProfiles';
import { EnrollmentStatuses, TeamStateIds, UserInfoStatus, UserStatus } from 'api/generated/enums';
import { IUser, IUserProfile } from 'api/generated/models';
import { ViewUserWageIncrease } from 'api/generated/permissions';
import {
    MEMBER_STATUS_COLUMN_ID,
    MEMBER_STATUS_FILTER_ID,
    MemberStatusHeader,
    STATUS_PARAM,
    USER_STATUS_OPTIONS,
} from 'components/commonTableComponents/MemberStatusHeader';
import { COMMON_HEADER_DROPDOWN_TABLE_PROPS } from 'components/headerDropdown/HeaderDropdown';
import ReactTable from 'components/reactTable/ReactTable';
import { MINIMUM_DATE } from 'constants/date';
import { userInfoStatusPrettyNames } from 'constants/userInfoStatus';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import UseThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { CENTER_COLUMN_PROPS } from 'pages/pathwayBlueprint/PathwayTableColumns';
import { setPeoplePagePaginationParams } from 'pages/people/peopleActions';
import { IPeopleState } from 'pages/people/peopleState';
import {
    ActionsCell,
    AddedHeader,
    EmailCell,
    ENROLLMENT_STATUS_OPTIONS,
    ENROLLMENT_STATUS_PARAM,
    EnrollmentStatusCell,
    EnrollmentStatusHeader,
    INFO_PARAM,
    InfoCell,
    InfoHeader,
    LoginCreatedDateCell,
    NameCell,
    ReimbursementHeader,
    StatusCell,
    WageIncreaseHeader,
} from 'pages/people/PeopleTableCells';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import {
    Cell,
    Column,
    Filters,
    FilterTypes,
    Row,
    TableRowProps,
    TableState,
    useAsyncDebounce,
} from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { ITeamProps } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { hasContents, hasValue } from 'utilities';
import { formatCurrency, formatDateForDisplay } from 'utilities/format';
import { getDateOrNull, stripTimeFromDate } from 'utilities/moment';
import { DEFAULT_ACTIONS_COLUMN_PROPS, getColumnWidth } from 'utilities/reactTable';

const NAME_COLUMN_ID = 'lastName';
const NAME_FILTER_ID = 'nameFilter';
const INFO_COLUMN_ID = 'userInfoStatus';
const INFO_FILTER_ID = 'infoFilter';
const ENROLLMENT_STATUS_COLUMN_ID = 'enrollmentStatus';
const ENROLLMENT_STATUS_FILTER_ID = 'enrollmentStatusFilter';

const DEBOUNCE_MILLISECONDS = 750;
const HALF_OPACITY = 0.5;
const FULL_OPACITY = 1;
const getRowProps = (row: Row<IUserProfile>): TableRowProps => ({
    key: row?.id,
    style: {
        opacity: row?.original.user?.status === UserStatus.Inactive ? HALF_OPACITY : FULL_OPACITY,
    },
});

const getRowId = (originalRow: IUserProfile) => originalRow.user?.userId as string;

const getReimbursementInfo = (user: IUser | undefined, selectedYear: string) => {
    const activeContribution = user?.activeWageUps?.find((a) => a.year === Number(selectedYear));
    const hasReimbursement = hasValue(activeContribution?.reimbursement);
    const reimbursement = activeContribution?.reimbursement;
    return { hasReimbursement, reimbursement };
};

const filterTypes: FilterTypes<IUserProfile> = {
    [ENROLLMENT_STATUS_FILTER_ID]: (rows, _, filterValue: string[]) =>
        rows.filter(
            ({ original: { yearlyUserInfo } }) =>
                (yearlyUserInfo?.enrollmentStatus === undefined && filterValue.includes('None')) ||
                filterValue.includes(
                    startCase(
                        EnrollmentStatuses[
                            yearlyUserInfo?.enrollmentStatus?.value as EnrollmentStatuses
                        ]
                    )
                )
        ),
    [INFO_FILTER_ID]: (rows, _, filterValue: string[]) =>
        rows.filter(({ original: { user } }) =>
            filterValue.contains(userInfoStatusPrettyNames[user?.userInfoStatus as UserInfoStatus])
        ),
    [MEMBER_STATUS_FILTER_ID]: (rows, _, filterValue: string[]) =>
        rows.filter(
            ({ original: { user } }) =>
                (user?.status === undefined && filterValue.includes('None')) ||
                filterValue.includes(startCase(UserStatus[user?.status as UserStatus]))
        ),
};

const peopleTableSelector = ({ teamStateId }: ITeamProps) => (state: AppStore) => ({
    canViewUserWageIncrease: state.login.up.includes(ViewUserWageIncrease),
    hasAnyReimbursements: state.userProfilesMetadata.hasAnyReimbursements,
    isEnrollmentStatusColumnVisible: [
        TeamStateIds.Customer,
        TeamStateIds.MemberLaunch,
        TeamStateIds.Renewing,
    ].includes(teamStateId),
    isLoginCreatedDateColumnVisible: isRhSelector(state) || isAdvisorSelector(state),
    isTeamStateProspect: teamStateId === TeamStateIds.Prospect,
    paginationParams: state.peopleState,
    selectedYear: state.profileState.selectedYear,
    showActivity: hasApiActivity(
        state,
        LIST_PAGED_USER_PROFILES_ACTION,
        GET_CURRENT_USER_BUDGETS_FOR_TEAM_ACTION,
        GET_USER_PROFILES_METADATA_ACTION
    ),
    totalActiveMembers: state.userProfilesMetadata.totalActiveMembers,
    userBudgets: state.userBudgets,
    userProfiles: state.pagedUserProfiles,
});

type IPeopleTableProps = {
    search?: string;
};

const PeopleTable = ({ search }: IPeopleTableProps) => {
    const dispatch = UseThunkDispatch();
    const [query] = useQuery();
    const [pendingRequest, setPendingRequest] = useState<boolean>(false);
    const { selectedMembers, setSelectedMembers } = useContext(TeamManagementContext);
    const statusQueryParam = query.get(STATUS_PARAM)?.split(',');
    const enrollmentStatusQueryParam = query.get(ENROLLMENT_STATUS_PARAM)?.split(',');
    const infoQueryParam = query.get(INFO_PARAM)?.split(',');
    const teamProps = useTeamProps();
    const {
        canViewUserWageIncrease,
        hasAnyReimbursements,
        isEnrollmentStatusColumnVisible,
        isLoginCreatedDateColumnVisible,
        isTeamStateProspect,
        paginationParams,
        selectedYear,
        showActivity,
        totalActiveMembers,
        userBudgets,
        userProfiles,
    } = useSelector(peopleTableSelector(teamProps));
    const listPagedProfiles = useCallback(
        (paginationParamsInternal: IPeopleState) => {
            setPendingRequest(false);
            return listPagedUserProfiles(teamProps.teamId, +selectedYear, paginationParamsInternal);
        },
        [selectedYear, teamProps.teamId]
    );
    const debouncedListPageProfiles = useAsyncDebounce(() => {
        dispatch(listPagedProfiles(paginationParams));
    }, DEBOUNCE_MILLISECONDS);
    useEffect(() => {
        setPendingRequest(true);
        if (+selectedYear > 0) {
            debouncedListPageProfiles();
        }
        return () => {
            dispatch(clearPagedUsers());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedListPageProfiles, dispatch, search, selectedYear]);
    const onFetchData = (state: TableState<IUserProfile>) => {
        dispatch(setPeoplePagePaginationParams(state));
        if (+selectedYear > 0) {
            setPendingRequest(true);
            debouncedListPageProfiles();
        }
    };
    useEffect(() => {
        if (hasValue(teamProps.teamId) && hasValue(+selectedYear) && +selectedYear > 0) {
            dispatch(getUserProfilesMetadata(teamProps.teamId, +selectedYear));
        }
        return () => {
            dispatch(clearUserProfilesMetadata());
        };
        // eslint-disable-next-line
    }, [selectedYear]);

    const initialFilters: Filters<IUserProfile> = useMemo(
        () => [
            {
                id: INFO_COLUMN_ID,
                value: hasContents(infoQueryParam) ? infoQueryParam : userInfoStatusPrettyNames,
            },
            {
                id: MEMBER_STATUS_COLUMN_ID,
                value: hasContents(statusQueryParam)
                    ? statusQueryParam
                    : USER_STATUS_OPTIONS.filter((x) => x !== 'Inactive'),
            },
            {
                id: ENROLLMENT_STATUS_COLUMN_ID,
                value: hasContents(enrollmentStatusQueryParam)
                    ? enrollmentStatusQueryParam
                    : ENROLLMENT_STATUS_OPTIONS,
            },
        ],
        [enrollmentStatusQueryParam, infoQueryParam, statusQueryParam]
    );

    const getBudgetInfo = useCallback(
        ({ user }: IUserProfile) => {
            const activeWageUp = user?.activeWageUps?.find(
                (x) => Number(x.year) === Number(selectedYear)
            );
            if (activeWageUp?.wageUp) {
                return formatCurrency(activeWageUp.wageUp);
            }
            const userBudget = userBudgets.find((x) => x.userId === user?.userId);
            return userBudget ? formatCurrency(userBudget.supplementalWageIncrease) : '';
        },
        [selectedYear, userBudgets]
    );
    const wageIncreaseColumn: Column<IUserProfile> = useMemo<Column<IUserProfile>>(
        () => ({
            ...CENTER_COLUMN_PROPS,
            accessor: (up) => getBudgetInfo(up),
            Cell: ({ value }: Cell<IUserProfile, string>) => value,
            Header: WageIncreaseHeader,
            id: 'wagePlus',
            width: 70,
        }),
        [getBudgetInfo]
    );
    const getFormattedReimbursement = useCallback(
        ({ user }: IUserProfile) => {
            const { hasReimbursement, reimbursement } = getReimbursementInfo(user, selectedYear);
            return hasReimbursement ? formatCurrency(reimbursement, { preserveDecimal: true }) : '';
        },
        [selectedYear]
    );
    const reimbursementColumn: Column<IUserProfile> = useMemo<Column<IUserProfile>>(
        () => ({
            ...CENTER_COLUMN_PROPS,
            accessor: (up) => getFormattedReimbursement(up),
            Cell: ({ value }: Cell<IUserProfile, string>) => value,
            Header: ReimbursementHeader,
            id: 'reimbursement',
            width: 120,
        }),
        [getFormattedReimbursement]
    );

    const statusColumn: Column<IUserProfile> = useMemo(
        () => ({
            ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
            accessor: (up) => startCase(UserStatus[up.user?.status as UserStatus]),
            Cell: StatusCell,
            filter: MEMBER_STATUS_FILTER_ID,
            Header: MemberStatusHeader,
            id: MEMBER_STATUS_COLUMN_ID,
            width: 120,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const enrollmentStatusColumn: Column<IUserProfile> = useMemo(
        () => ({
            ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
            accessor: (up) =>
                startCase(
                    EnrollmentStatuses[
                        up.yearlyUserInfo?.enrollmentStatus?.value as EnrollmentStatuses
                    ]
                ),
            Cell: EnrollmentStatusCell,
            filter: ENROLLMENT_STATUS_FILTER_ID,
            Header: EnrollmentStatusHeader,
            id: ENROLLMENT_STATUS_COLUMN_ID,
            width: 140,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const columns: Column<IUserProfile>[] = useMemo(() => {
        const cols = [
            {
                accessor: (up) => `${up.user?.lastName} ${up.user?.firstName}`,
                Cell: NameCell,
                filter: NAME_FILTER_ID,
                Header: 'Name',
                id: NAME_COLUMN_ID,
                width: 200,
            },
            {
                ...COMMON_HEADER_DROPDOWN_TABLE_PROPS,
                userInfoStatusPrettyNames,
                accessor: (up) => up.user?.userInfoStatus,
                Cell: InfoCell,
                disableSortBy: true,
                filter: INFO_FILTER_ID,
                Header: InfoHeader,
                id: INFO_COLUMN_ID,
                width: 40,
            },
            {
                accessor: (up) => up.user?.email,
                Cell: EmailCell,
                disableSortBy: true,
                Header: 'Email',
                id: 'email',
                width: getColumnWidth(userProfiles.results ?? [], (x) => x.user?.email, 'Email'),
            },
        ] as Column<IUserProfile>[];
        if (!isTeamStateProspect) {
            cols.push(statusColumn);
        }

        if (isEnrollmentStatusColumnVisible) {
            cols.push(enrollmentStatusColumn);
        }

        if (!isTeamStateProspect && canViewUserWageIncrease) {
            cols.push(wageIncreaseColumn);
            if (hasAnyReimbursements) {
                cols.push(reimbursementColumn);
            }
        }
        cols.push({
            accessor: (up) => stripTimeFromDate(up.user?.createdDate)?.toDate() ?? MINIMUM_DATE,
            Cell: ({
                row: {
                    original: { user },
                },
            }: Cell<IUserProfile, moment.Moment>) => formatDateForDisplay(user?.createdDate) ?? '',
            Header: AddedHeader,
            id: 'createdDate',
            sortType: 'datetime',
            width: 80,
        });

        if (isLoginCreatedDateColumnVisible) {
            cols.push({
                accessor: (up) =>
                    getDateOrNull(up.loginCreatedDate, stripTimeFromDate)?.toDate() ?? MINIMUM_DATE,
                Cell: LoginCreatedDateCell,
                Header: 'Login Created Date',
                id: 'loginCreatedDate',
                sortType: 'datetime',
                width: 80,
            });
        }

        cols.push({
            accessor: (up) => getDateOrNull(up.user?.surveyLastSentDate)?.toDate() ?? MINIMUM_DATE,
            Cell: ({
                row: {
                    original: { user },
                },
            }: Cell<IUserProfile, moment.Moment>) =>
                formatDateForDisplay(user?.surveyLastSentDate) ?? '',
            Header: 'Survey Last Sent',
            id: 'surveyLastSentDate',
            sortType: 'datetime',
            width: 80,
        });

        cols.push({
            accessor: (up) => getDateOrNull(up.user?.inviteLastSentDate)?.toDate() ?? MINIMUM_DATE,
            Cell: ({
                row: {
                    original: { user },
                },
            }: Cell<IUserProfile, moment.Moment>) =>
                formatDateForDisplay(user?.inviteLastSentDate) ?? '',
            Header: 'Invite Last Sent',
            id: 'inviteLastSentDate',
            sortType: 'datetime',
            width: 80,
        });

        cols.push({
            ...DEFAULT_ACTIONS_COLUMN_PROPS,
            Cell: ActionsCell,
            width: 220,
        });
        return cols;
    }, [
        canViewUserWageIncrease,
        enrollmentStatusColumn,
        hasAnyReimbursements,
        isEnrollmentStatusColumnVisible,
        isLoginCreatedDateColumnVisible,
        isTeamStateProspect,
        reimbursementColumn,
        statusColumn,
        userProfiles.results,
        wageIncreaseColumn,
    ]);

    return (
        <ReactTable
            allPossibleRowIds={totalActiveMembers ?? []}
            checkedRowIds={selectedMembers}
            columns={columns}
            data={userProfiles.results ?? []}
            filterTypes={filterTypes}
            getCustomRowProps={getRowProps}
            getRowId={getRowId}
            hideAllPageSizeOption
            initialFilters={initialFilters}
            isRowSelectionEnabled={teamProps.teamStateId === TeamStateIds.Prospect}
            loading={showActivity || pendingRequest}
            manualPagination
            onFetchData={onFetchData}
            pageSize={paginationParams.pageSize}
            rowsWhenNoData={paginationParams.pageSize}
            setCheckedRowIds={(x: string[]) => {
                setSelectedMembers?.(x);
            }}
            totalCount={userProfiles.totalCount}
        />
    );
};

export default hot(module)(PeopleTable);
