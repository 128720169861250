import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { clearIdeonCarriers, clearSupportedOffExchangeStates } from 'actions/clear';
import {
    ADD_WHITELISTED_CARRIERS_ACTION,
    addWhitelistedCarriers,
} from 'actions/whitelistedCarriers/addWhitelistedCarriers';

type StateOption = {
    state: string;
};

const mapStateToStateOption = (state: string): StateOption => ({ state });

import {
    GET_SUPPORTED_OFF_EXCHANGE_STATES_ACTION,
    getSupportedOffExchangeStates,
} from 'actions/whitelistedCarriers/getSupportedOffExchangeStates';
import { getWhitelistedCarriers } from 'actions/whitelistedCarriers/getWhitelistedCarriers';
import { AddCarrierDto, IIdeonCarrierDto } from 'api/generated/models';
import { MIN_CHARACTERS_TO_SEARCH } from 'components/Autocomplete';
import Button from 'components/Button';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import useThunkDispatch from 'hooks/useThunkDispatch';
import IdeonCarriersTable from 'pages/whitelistedCarrierManagement/IdeonCarriersTable';
import {
    SEARCH_IDEON_CARRIERS_ACTION,
    searchIdeonCarriers,
} from 'pages/whitelistedCarrierManagement/searchIdeonCarriers';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Row } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { arrayHasValue, hasValue } from 'utilities/index';
import { years } from 'utilities/year';

const AddWhitelistedCarrierModal = ({
    initialYear,
    onClose,
}: {
    initialYear: number;
    onClose: () => void;
}) => {
    const dispatch = useThunkDispatch();

    const {
        isAddingWhitelist,
        isLoadingStates,
        isSearchingCarriers,
        supportedOffExchangeStates,
    } = useSelector((appState: AppStore) => ({
        isAddingWhitelist: hasApiActivity(appState, ADD_WHITELISTED_CARRIERS_ACTION),
        isLoadingStates: hasApiActivity(appState, GET_SUPPORTED_OFF_EXCHANGE_STATES_ACTION),
        isSearchingCarriers: hasApiActivity(appState, SEARCH_IDEON_CARRIERS_ACTION),
        supportedOffExchangeStates: appState.supportedOffExchangeStates,
    }));

    const [carrierYear, setCarrierYear] = useState(initialYear);
    const [searchTerm, setSearchTerm] = useState('');
    const [state, setState] = useState('');
    const [selection, setSelection] = useState<Row<IIdeonCarrierDto>[]>([]);

    const search = () => {
        dispatch(searchIdeonCarriers(searchTerm, state, carrierYear));
    };

    const changeCarrierYear = (year: number) => {
        setCarrierYear(year);
        setState('');
        dispatch(clearIdeonCarriers());
    };

    const changeState = (newState: string) => {
        setState(newState);
        dispatch(clearIdeonCarriers());
    };

    const addCarriers = async () => {
        const carriers: AddCarrierDto[] = selection.map(
            (x) =>
                new AddCarrierDto({
                    carrierId: x.original.id,
                    carrierName: x.original.name,
                })
        );

        await dispatch(addWhitelistedCarriers(carriers, state, carrierYear));
        if (initialYear === carrierYear) {
            await dispatch(getWhitelistedCarriers(carrierYear));
        }
        onClose();
    };

    useEffect(() => {
        if (carrierYear > 0) {
            dispatch(getSupportedOffExchangeStates(carrierYear));
        }
    }, [carrierYear, dispatch]);

    useEffect(
        () => () => {
            dispatch(clearSupportedOffExchangeStates());
            dispatch(clearIdeonCarriers());
        },
        [dispatch]
    );

    return (
        <Dialog fullWidth maxWidth="xl" onClose={onClose} open>
            <DialogTitle>Add Whitelisted Carrier</DialogTitle>
            <DialogContent>
                <Stack
                    alignItems="center"
                    direction={{ md: 'row', xs: 'column' }}
                    justifyContent="center"
                    marginTop={2}
                    spacing={2}
                >
                    <Select
                        defaultText="Year"
                        defaultValue=""
                        items={years}
                        label="Year"
                        name="year"
                        onChange={onChange(changeCarrierYear)}
                        optionText="name"
                        optionValue="name"
                        sx={{ maxWidth: 100, minWidth: 100 }}
                        value={carrierYear}
                    />
                    <Skeleton count={1} height="56px" isEnabled={isLoadingStates}>
                        <Select
                            defaultText="Choose a State"
                            defaultValue=""
                            items={supportedOffExchangeStates.map(mapStateToStateOption)}
                            label="State"
                            name="state"
                            onChange={onChange(changeState)}
                            optionText="state"
                            optionValue="state"
                            sx={{ maxWidth: 100, minWidth: 100 }}
                            value={state}
                        />
                    </Skeleton>
                    <TextField
                        onChange={onChange(setSearchTerm)}
                        placeholder={`Type at least ${MIN_CHARACTERS_TO_SEARCH} characters to search carriers...`}
                        sx={{ maxWidth: 1000, minWidth: 100 }}
                        value={searchTerm}
                    />
                    <Button
                        disabled={
                            searchTerm.trim().length < MIN_CHARACTERS_TO_SEARCH || !hasValue(state)
                        }
                        isLoading={isSearchingCarriers}
                        onClick={search}
                    >
                        Search Carriers
                    </Button>
                </Stack>
                <IdeonCarriersTable setSelection={setSelection} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text">
                    Close
                </Button>
                <Button
                    disabled={!arrayHasValue(selection)}
                    isLoading={isAddingWhitelist}
                    onClick={async () => addCarriers()}
                    type="submit"
                    variant="text"
                >
                    {`Add (${selection.length})`}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(AddWhitelistedCarrierModal);
