import { getUserBudgetsForTeam } from 'actions/team/getUserBudgetsForTeam';
import { getTeamUserRoles } from 'actions/user/getTeamUserRoles';
import { TeamStateIds } from 'api/generated/enums';
import { ViewUserRoles } from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import PageSectionWrapper from 'components/PageSectionWrapper';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import PeoplePageHeader from 'pages/people/PeoplePageHeader';
import PeopleTable from 'pages/people/PeopleTable';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

const PeoplePage = () => {
    const dispatch = useThunkDispatch();
    const { activeDate, hasTeam, teamId, teamStateId } = useTeamProps();
    const { canViewUserRoles, search, selectedYear } = useSelector((state: AppStore) => ({
        canViewUserRoles: state.login.up.includes(ViewUserRoles),
        search: state.peopleState.search,
        selectedYear: state.profileState.selectedYear,
    }));
    const [selectedMembers, setSelectedMembers] = useState<string[] | undefined>([]);
    const { isTeamManagementPage } = useContext(TeamManagementContext);

    const teamManagementContextValue = useMemo(
        () => ({
            isTeamManagementPage,
            selectedMembers,
            setSelectedMembers,
        }),
        [isTeamManagementPage, selectedMembers]
    );

    useEffect(() => {
        if (!hasValue(selectedYear) && hasTeam) {
            const teamActiveYear =
                activeDate?.getYear().toString() ?? new Date().getUTCFullYear().toString();
            dispatch(setSelectedYear(teamActiveYear));
        }
    }, [activeDate, dispatch, hasTeam, selectedYear]);
    useEffect(() => {
        if (
            teamStateId !== TeamStateIds.Prospect &&
            (teamStateId as number) !== -1 &&
            hasValue(selectedYear)
        ) {
            dispatch(getUserBudgetsForTeam(teamId, +selectedYear));
        }
    }, [dispatch, selectedYear, teamId, teamStateId]);
    useEffect(() => {
        if (canViewUserRoles) {
            dispatch(getTeamUserRoles(teamId, ROLE_IDS.TEAM_ADMIN, ROLE_IDS.TEAM_PAYROLL_ADMIN));
        }
    }, [canViewUserRoles, dispatch, teamId]);

    return (
        <TeamManagementContext.Provider value={teamManagementContextValue}>
            <PageSectionWrapper>
                <PeoplePageHeader />
                <PeopleTable search={search} />
            </PageSectionWrapper>
        </TeamManagementContext.Provider>
    );
};

export default hot(module)(PeoplePage);
